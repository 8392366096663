import React from 'react';
import { ReactComponent as EmailIcon } from './icons/EmailIcon.svg';

const Authors = () => {
    return (
        <div id='authors-container'>
            <div className='design-unbound-header-element'>
                <h2>
                    Ann Pendleton-Jullian
                    <br />
                    and John Seely Brown
                </h2>
            </div>
            <div className='design-unbound-main-elements'>
                <div className='design-unbound-left-element'>
                    <img
                        src={ `${process.env.PUBLIC_URL}/assets/authors.jpg` }
                        alt='Design Unbound authors - APJ and JSB'
                    />
                </div>
                <div className='design-unbound-right-element'>
                    <div className='author-apj'>
                        <p>
                            <b>Ann Pendleton-Jullian</b> is an architect, writer, and educator of international standing whose
                            work explores the interchange between architecture, landscape, culture, science, and
                            technology within complex contexts. She is currently Full Professor and former Director of
                            the Knowlton School of Architecture at Ohio State University, advisor to the President of
                            Georgetown University, and advisor and distinguished Visiting Professor at the Pardee
                            RAND Graduate School of Public Policy. She has co-taught world building studios at
                            USC’s School of Cinema. From 1993-2007, she was a tenured professor of architecture
                            at MIT.
                        </p>
                        <p>
                            ApJ’s projects range in scale and scope from things to systems of action: from houses to
                            hotels, a Congress Hall in Chile, universities and cultural buildings. Much of her recent
                            work focuses on empowerment and economic development through various projects
                            including the Asian University for Women in Bangladesh and an eight-village ecosystem
                            conceived around rural craft tourism in Guizhou province in China.
                        </p>
                        <p>
                            ApJ entered the field of architecture after missing ‘architecture’ in the course catalogue
                            and instead studying astrophysics. This and growing up in the Midwest of Ray Bradbury
                            has tinged her perspective on pretty much everything.
                        </p>
                        <span className='link-with-icon'>
                            <EmailIcon />
                            <a href="mailto:annpjull@gmail.com">Contact ApJ</a>
                        </span>
                    </div>
                    <hr className='author-separator' />
                    <div className='author-jsb'>
                        <p>
                            <b>John Seely Brown</b> (JSB) was Chief Scientist of Xerox Corporation as well as the director
                            of the Xerox Palo Alto Research Center (PARC) until 2002. A master integrator and
                            instigator of productive friction, JSB explores the whitespace between disciplines and
                            builds bridges between disparate organizations and ideas. In his more than two decades
                            at PARC, Brown transformed the organization into a truly multidisciplinary research
                            center at the creative edge of applied technology and design, integrating social sciences
                            and arts into the traditional physics and computer science research and expanding the
                            role of corporate research to include topics such as the management of radical
                            innovation, organizational learning, and complex adaptive systems.
                        </p>
                        <p>
                            JSB is currently a visiting scholar and advisor to the Provost at the University of Southern
                            California (USC) and the Independent Co-Chairman for Deloitte’s Center for the Edge.
                            He is a member of the American Academy of Arts and Sciences, the National Academy
                            of Education, a Fellow of the American Association for Artificial Intelligence and has
                            served on numerous private and public boards of directors, including Amazon.
                        </p>
                        <span className='link-with-icon'>
                            <EmailIcon />
                            <a href="mailto:jsb@johnseelybrown.com">Contact JSB</a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Authors;