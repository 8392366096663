import React, { Component } from 'react';
import HttpsRedirect from 'react-https-redirect';

import Nav from './components/Nav';
import Router from './components/Router';

class App extends Component {

    constructor(props) {
        super(props);
        this.onLocationChange = this.onLocationChange.bind(this);
    }

    onLocationChange() {
        window.scrollTo(0,0);
    }

    render() {
        return (
            <HttpsRedirect>
                <div>
                    <Nav />
                    <div id='app-content'>
                        <Router onLocationChange={ this.onLocationChange } />
                        <div id='app-footer'>
                            <p>© 2019 Ann Pendleton-Jullian and John Seely Brown</p>
                            <p>Website by Mars Jullian</p>
                        </div>
                    </div>
                </div>
            </HttpsRedirect>
        );
    }
}

export default App;
