import { useRoutes } from 'hookrouter';
import _reduce from 'lodash.reduce';

import routeConfig from '../config/routeConfig';

const routes = _reduce(routeConfig, (result, value, key) => {
    result[key] = value.routeFn;
    return result;
}, {});

const Router = (props) => {
    props.onLocationChange && props.onLocationChange();

    return useRoutes(routes) || '404';
};

export default Router;
