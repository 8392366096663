import React, { Component } from 'react';
import _map from 'lodash.map';

import PageWithTOC from './PageWithTOC';

class LookInside extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    handleImgLoad(sectionId) {
        const numLoaded = this.state[sectionId] || 0;
        this.setState({ [sectionId]: numLoaded + 1 });
    }

    renderImages(sectionId, data) {
        const { numImages, imgPrefix } = data;
        const imgElements = [];

        for (let i = 1; i <= numImages; i++) {
            imgElements.push((
                <img
                    key={ `${sectionId}-img-${i}` }
                    src={ `${process.env.PUBLIC_URL}/assets/look-inside/${sectionId}/${imgPrefix}_${i}.jpg` }
                    className='look-inside-img'
                    onLoad={ () => this.handleImgLoad(sectionId) }
                />
            ));
        }

        return imgElements;
    }

    render() {
        return (
            <div id="look-inside-container">
                <PageWithTOC
                    sections={ _map(this.props.data, (data, key) => ({
                        id: key,
                        label: data.label,
                        children: this.renderImages(key, data),
                        contentLoaded: this.state[key] === data.numImages
                    }))}
                />
            </div>
        );
    }
}

export default LookInside;