import React from 'react';

import Landing from '../components/Landing';
import Chapters from '../components/Chapters';
import Chapter from '../components/Chapter';
import Authors from '../components/Authors';
import Comments from '../components/Comments';
import LookInside from '../components/LookInside';

import chapterData from './chapters.json';
import commentsData from './comments.json';
import lookInsideData from './lookInside.json';

const routeConfig = {
    '/': {
        title: 'Design Unbound',
        label: 'Home',
        routeFn: () => <Landing />,
        inMenu: true
    },

    '/chapters': {
        title: 'Chapters',
        routeFn: () => <Chapters data={chapterData} />,
        inMenu: true
    },

    '/look-inside': {
        title: 'Look inside',
        routeFn: () => <LookInside data={ lookInsideData }/>
    },

    '/reader-comments': {
        title: 'Reader Comments',
        routeFn: () => <Comments data={commentsData} />,
        inMenu: true
    },

    '/about-the-authors': {
        title: 'About the Authors',
        routeFn: () => <Authors />,
        inMenu: true
    },

    '/chapter/:id': {
        routeFn: ({ id }) => (
            <Chapter
                chapterId={id}
                {...chapterData[id]}
            />
        )
    },

    'pragmaticimagination.com': {
        title: 'pragmaticimagination.com',
        inMenu: true
    }
};

export default routeConfig;