import React, { Component } from 'react';
import _map from 'lodash.map';

import BookCover from './BookCover';

class Chapters extends Component {

    constructor(props) {
        super(props);

        this.state = {
            clearOverlays: false
        };

        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        this.setState({ clearOverlays: true });

        setTimeout(
            () => this.setState({ clearOverlays: false }),
            100
        );
    }

    render() {
        return (
            <div id='chapters-container'>
                <div className='design-unbound-header-element'>
                    <h1 className='main-title'>
                        Chapters
                    </h1>
                </div>
                <div className='chapter-covers'>
                    { _map(this.props.data, (chapter, id) => (
                        <BookCover
                            href={ `/chapter/${id}` }
                            key={ id }
                            clearOverlays={ this.state.clearOverlays }
                            classes='chapter-cover-container'
                            title={ chapter.title }
                            src={ `${process.env.PUBLIC_URL}/assets/chapter-covers/${id}.jpg` }
                            description={ chapter.description.join(' ') }
                            bgColor={ chapter.color }
                            hasDownload={ !!chapter.downloadAsset }
                        />
                    ))}
                </div>
            </div>
        );
    }
}

export default Chapters;