import React from 'react';
import _map from 'lodash.map';
import { A } from 'hookrouter';

import { ReactComponent as ReadMore } from './icons/MoreButton.svg';
import { ReactComponent as DownloadButton } from './icons/ArrowDown.svg';
import { ReactComponent as ArrowForward } from './icons/ArrowForward.svg';
import { ReactComponent as PurchaseVolume1 } from './icons/PurchaseVolume1.svg';
import { ReactComponent as PurchaseVolume2 } from './icons/PurchaseVolume2.svg';

import ChapterData from '../config/chapters.json';

const Index = () => (
    <div id='design-unbound-volumes-container'>
        <div className='design-unbound-header-element'>
            <h1 className='main-title'>
                Design Unbound. Designing for Emergence in a White Water World
            </h1>
            <div className='author-tag'>
                <A href='/about-the-authors'>
                    Ann Pendleton-Jullian
                    <br />
                    and John Seely Brown
                </A>
            </div>
        </div>
        <div className='design-unbound-main-elements'>
            <div className='design-unbound-left-element'>
                <img
                    className='design-unbound-volume-cover'
                    src={ `${process.env.PUBLIC_URL}/assets/volume-covers/volume-1.jpg` }
                    alt='Design Unbound - Volume 1'
                />
                <img
                    className='design-unbound-volume-cover'
                    src={ `${process.env.PUBLIC_URL}/assets/volume-covers/volume-2.jpg` }
                    alt='Design Unbound - Volume 2'
                />
                
            </div>
            <div className='design-unbound-right-element'>
                <h2 className='mit-press-tag'>
                    MIT Press 2018
                </h2>
                <div className='volume-tag'>
                    two volume set
                </div>  
                <p>
                    <i>Design Unbound</i> presents a new tool set for having agency in the twenty-first century,
                    in what the authors characterize as a white water world―rapidly changing, hyperconnected,
                    and radically contingent. These are the tools of a new kind of practice that is the offspring
                    of complexity science, which gives us a new lens through which to view the world as entangled
                    and emerging, and architecture, which is about designing contexts. In such a practice, design,
                    unbound from its material thingness, is set free to design contexts as complex systems.
                </p>
                <div className='link-set'>
                    <span className='link-with-icon'>    
                        <PurchaseVolume1 />
                        <a href='https://www.amazon.com/Design-Unbound-Designing-Emergence-Infrastructures/dp/0262535793/'>
                            Purchase Volume 1
                        </a>
                    </span>
                    <span className='link-with-icon'>
                        <PurchaseVolume2 />
                        <a href='https://www.amazon.com/Design-Unbound-Designing-Emergence-Infrastructures/dp/0262535823/'>
                            Purchase Volume 2
                        </a>
                    </span>
                    <span className='link-with-icon'>
                        <ReadMore />
                        <A href='/look-inside'>
                            Look inside
                        </A>
                    </span>
                    <span className='link-with-icon'>
                        <DownloadButton />
                        <a href={ `${process.env.PUBLIC_URL}/assets/DesUnbound_brochure.pdf` }>
                            Download brochure
                        </a>
                    </span>
                </div>
                <div className='design-unbound-chapter-container'>
                    <A className='chapters-link' href='/chapters'>
                        Individual chapters of <i>Design Unbound</i> are available for classes,
                        conferences & convenings <ArrowForward />
                    </A>
                    <div className='design-unbound-chapters'>
                        { _map(ChapterData, (chapter, id) => {
                            if (!chapter.showOnHomepage) return null;
                            return (
                                <A href='/chapters' className='chapter'>
                                    <img
                                        key={ id }
                                        alt={ chapter.title }
                                        src={ `${process.env.PUBLIC_URL}/assets/chapter-covers/${id}.jpg` }
                                    />
                                </A>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default Index;