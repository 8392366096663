import React, { Component } from 'react';
import _map from 'lodash.map';

class PageWithTOC extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeSection: props.sections[0].id
        };

        this.renderLink = this.renderLink.bind(this);
        this.renderSection = this.renderSection.bind(this);
        this.handleScrollEnd = this.handleScrollEnd.bind(this);

        this.containerRef = null;
        this.linksRef = null;
    }

    /**
     * Update the page header depending on which comment section is visible.
     */
    componentDidMount() {
        let timer;

        window.addEventListener('scroll', () => {
            clearTimeout(timer);
            timer = setTimeout(this.handleScrollEnd, 50);
        });
    }

    handleScrollEnd() {
        const { sections } = this.props;
        const viewportHeight = Math.max(
            document.documentElement.clientHeight,
            window.innerHeight
        );
        const visibleHeightThreshold = viewportHeight / 3;

        // loop through sections for first one with a non-negative `top` value
        for (let i = 0; i < sections.length; i++) {
            const { id } = sections[i];
            const sectionEl = this[`${id}-ref`];
            const boundingClientRect = sectionEl.getBoundingClientRect();

            const isVisible = boundingClientRect.top > visibleHeightThreshold
                || boundingClientRect.bottom > visibleHeightThreshold;

            if (isVisible) {
                this.setState({ activeSection: id })
                break;
            }
        }
    }

    /**
     * Handle smooth scrolling when clicking on one of the header links
     */
    handleLinkClick(e, sectionId, isDisabled) {
        e.preventDefault();
        if (isDisabled) return;

        const sectionRef = this[`${sectionId}-ref`];
        const boundingClientRect = sectionRef.getBoundingClientRect();
        const linksClientRect = this.linksRef.getBoundingClientRect();

        window.scrollTo({
            top: boundingClientRect.top + window.scrollY - linksClientRect.height,
            behavior: 'smooth'
        });

        // make sure the header section updates as well, since the scroll
        // doesn't trigger the intersection observer
        this.setState({ activeSection: sectionId });
    }

    /**
     * Rendering
     */
    renderLink(section) {
        const { id, label, contentLoaded } = section;
        return (
            <a
                href={ `#${id}` }
                className={`
                    toc-section-link
                    ${ this.state.activeSection === id ? 'highlighted': '' }
                    ${ contentLoaded ? '' : 'disabled' }

                `}
                onClick={ e => this.handleLinkClick(e, id, !contentLoaded) }
            >
                { contentLoaded ? label : `${label} (loading...)` }
            </a>
        )
    }

    renderSection(section) {
        const { id, children } = section;

        return (
            <a
                name={ id }
                ref={ el => this[`${id}-ref`] = el } 
            >
                { children }
            </a>
        );
    }

    render() {
        return (
            <div className='design-unbound-main-elements with-toc' ref={ el => this.containerRef = el }>
                <div className='design-unbound-left-element' ref={ el => this.linksRef = el }>
                    { _map(this.props.sections, this.renderLink) }
                </div>
                <div className='design-unbound-right-element'>
                    { _map(this.props.sections, this.renderSection) }
                </div>
            </div>
        );
    }
}

export default PageWithTOC;