import React, { Component } from 'react';
import _map from 'lodash.map';
import classnames from 'classnames';
import { A } from 'hookrouter';

import MenuIcon from './icons/MenuIcon';
import CloseIcon from './icons/CloseIcon';

import routeConfig from '../config/routeConfig';

class Nav extends Component {

    constructor(props) {
        super(props);

        this.state = {
            navClicked: false,
            navOpen: false
        };

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.setState({
            navClicked: true,
            navOpen: !this.state.navOpen
        });
    }

    render() {
        const { navClicked, navOpen } = this.state;
        const currentPath = window && window.location && window.location.pathname;

        const navClasses = classnames('', {
            'animate-right': navClicked && navOpen,
            'animate-left': navClicked && !navOpen
        });

        return (
            <nav>
                <span
                    onClick={ this.handleClick }
                    id='nav-control'
                    className={ navClasses }
                >
                    <CloseIcon classes={ navOpen ? '' : 'hide' } />
                    <MenuIcon classes={ navOpen ? 'hide' : '' } />
                </span>
                <div
                    id='nav-content'
                    className={ navClasses }
                >
                    { _map(routeConfig, (routeData, routeId) => {
                        if (!routeData.inMenu) return null;

                        const { title, label } = routeData;
                        const isActive = routeId === currentPath;
                        const linkName = label || title;

                        if (routeData.routeFn) {
                            return (
                                <A
                                    className={ `nav-link ${isActive ? 'active': ''}` }
                                    title={ linkName }
                                    key={ routeId }
                                    href={ routeId }
                                    onClick={ this.handleClick }
                                >
                                    { linkName }
                                </A>
                            );
                        } else {
                            return (
                                <a
                                    title={ linkName }
                                    key={ routeId }
                                    href={ `http://${routeId}` }
                                    className='nav-link'
                                >
                                    { linkName }
                                </a>
                            );
                        }
                    })}
                </div>
            </nav>
        );
    }
}

export default Nav;