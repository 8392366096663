import React from 'react';
import _map from 'lodash.map';
import { A } from 'hookrouter';

import { ReactComponent as BackButton } from './icons/ArrowBack.svg';
import { ReactComponent as DownloadButton } from './icons/ArrowDown.svg';

const Chapter = props => {
    const {
        title,
        chapterId,
        description,
        urls,
        downloadAsset,
        prices
    } = props;

    return (
        <React.Fragment>
            <div className='design-unbound-header-element'>
                <A
                    href='/chapters'
                    title='Back to Chapters'
                    className='chapters-link'
                >
                    <BackButton /> All Chapters
                </A>
            </div>
            <div className='design-unbound-main-elements'>
                <div className='design-unbound-left-element'>
                    <img
                        alt={ title }
                        src={ `${process.env.PUBLIC_URL}/assets/chapter-covers/${chapterId}.jpg` }
                    />
                </div>
                <div className='design-unbound-right-element'>
                    { _map(description, (desc, idx) => 
                        <p key={ idx }>{ desc }</p>)
                    }
                    <div className='button-container'>
                        { downloadAsset && (
                            <div className='download-button-container'>
                                <span className='link-with-icon'>
                                    <DownloadButton />
                                    <a href={ `${process.env.PUBLIC_URL}/assets/${downloadAsset}` }>
                                        Download free
                                    </a>
                                </span>
                            </div>
                        )}
                        <div className='purchase-button-container'>
                            <a
                                className='black-and-white purchase-button'
                                href={ urls['black-and-white'] }
                            >
                                { `Purchase in black & white – ${prices['black-and-white']}` }
                            </a>
                            <a 
                                className='color purchase-button'
                                href={ urls['color'] }
                            >
                                { `Purchase in color – ${prices['color']}` }
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Chapter;