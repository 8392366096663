import React, { Component } from 'react';
import { A, navigate } from 'hookrouter';
import classnames from 'classnames';

import { ReactComponent as ForwardButton } from './icons/ArrowForward.svg';

class BookCover extends Component {

    constructor(props) {
        super(props);

        this.state = { showOverlay: false };
        this.showOverlay = this.showOverlay.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (!props.clearOverlays) return state;

        return { showOverlay: false }
    }

    showOverlay(e) {
        e.preventDefault();

        if (this.state.showOverlay) {
            navigate(this.props.href);
        } else {
            this.setState({ showOverlay: true });
        }
    }

    render() {
        let description = this.props.description.split('. ');
        description = `${description[0]}.`

        const classes = classnames(this.props.classes, 'book-cover-container', {
            showOverlay: this.state.showOverlay
        });

        return (
            <A
                href={ this.props.href }    
                key={ this.props.id }
                className={ classes }
                onTouchEnd={ this.showOverlay }
            >
                <span className='overlay' />
                <span className='overlay-content'>
                    <span className='description'>
                        { description }
                    </span>
                    <span className='read-more'>
                        Read more <ForwardButton />
                    </span>
                </span>
                <img
                    alt={ this.props.title }
                    src={ this.props.src }
                />
                { this.props.hasDownload && (
                    <div class='banner-container'>
                        <div class='banner'>Free download</div>
                    </div>            
                )}
            </A>   
        );
    }
}

export default BookCover;