import React, { Component } from 'react';
import PageWithTOC from './PageWithTOC';

const SECTION_DES_UNBOUND = 'des-unbound-comments';
const SECTION_PRAG_IMAG = 'prag-imag-comments';

const shuffleComments = comments => comments.sort(() => Math.random() - 0.5);

class Comments extends Component {

    constructor(props) {
        super(props);

        const { data } = props;

        this.state = {
            desUnboundComments: shuffleComments(data.designUnbound),
            pragImagComments: shuffleComments(data.pragmaticImagination)
        };
    }

    renderComments(comments, idx) {
        return comments.map(comment => {
            const { quote, author, quoteImg } = comment;

            const quoteHtml = quoteImg
                ? `<img className='comment-img' src='${process.env.PUBLIC_URL}/assets/comments/${quoteImg}' />`
                : quote
                    .map(q => `<p>${q}</p>`)
                    .join('');

            const even = idx % 2 === 0;
            idx += quoteImg ? 0 : 1;

            return (
                <div className={ `design-unbound-comment ${quoteImg ? 'has-img' : '' } ${even ? 'even' : 'odd' }` }>
                    <div
                        className='design-unbound-comment-quote'
                        dangerouslySetInnerHTML={{ __html: quoteHtml }}
                    />
                    { author && (
                        <div className='design-unbound-comment-author'>
                            <span className='author'>
                                { author.name }
                            </span>
                            { author.desc && (
                                <span className='desc'>
                                    { ` · ${author.desc}` }
                                </span>
                            )}
                        </div>
                    )}
                </div>
            );
        });
    }

    render() {
        // Shuffle arrays on every page render
        const { desUnboundComments, pragImagComments } = this.state;

        return (
            <div id="comments-container">
                <PageWithTOC
                    sections={[{
                        id: SECTION_DES_UNBOUND,
                        label: 'Design Unbound',
                        children: this.renderComments(desUnboundComments, 0),
                        contentLoaded: true
                    }, {
                        id: SECTION_PRAG_IMAG,
                        label: 'Pragmatic Imagination',
                        children: this.renderComments(pragImagComments, 1),
                        contentLoaded: true
                    }]}
                />
            </div>
        );
    }
}

export default Comments;